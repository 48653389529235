
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import ApiService from "@/core/services/ApiService";
import { useToast } from "vue-toastification";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import store from "@/store";
import { useStore } from "vuex";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useRouter, useRoute } from "vue-router";

// Import loading overlay
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
// end import loading overlay

import Multiselect from "@vueform/multiselect";
import moment from "moment";

export default defineComponent({
  name: "Detail Sekolah",
  components: {
    Field,
    Loading, //loading overlay
    Multiselect,
  },
  props: {
    widgetClasses: String,
  },
  
  data() {
    return {

    // loading overlay setting
      isLoading: false,
      fullPage: true,
      isColor: "#007BFF",
      isBackgroundColor: "#A3A3A3",
      isOpacity: 0.5,
    // end loading overlay setting

      tableData: {
        name: "",
        studentCount: "",
        fundingName: "",
        salesAreaName: "",
        listImplementatorName: "",
        isMigratePsp: "",
        status: "",
        _id: "",
      },
    }
  },

  beforeMount() {
    const store = useStore();
    this.getData();
  },

  methods: {

    getData(){
      // loading overlay tampil
      this.isLoading = true;
      const toast = useToast();

      ApiService.getWithoutSlug('crmv2/main_school/admin_school/school')
      .then(({ data }) => {
        this.tableData.name = data.name;
        this.tableData.studentCount = data.studentCount;
        this.tableData.fundingName = data.fundingName;
        this.tableData.isMigratePsp = data.isMigratePsp;
        this.tableData.salesAreaName = data.salesAreaName;
        this.tableData.listImplementatorName = data.listImplementatorName;
        this.tableData.status = data.status;
        this.tableData._id = data._id;
        this.isLoading = false;
      })
      .catch((e) => {
        if (e.response.status == 401) {
          toast.error(e.response.data.detail);
          this.$router.push('/sign-in');
          this.isLoading = false;
        } else {
          toast.error(e.response.data.detail);
          this.isLoading = false;
        }
      })
    },

    formatDate: function (date) {
      return moment(date).format("DD-MM-YYYY");
    },

  },
});
